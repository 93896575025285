import { CartBaseController } from "./cart_base_controller";

/*
 * Retrieves payment methods and redirect to external checkouts
 */
export default class extends CartBaseController {
  static targets = ["form", "submit", "method"];

  get isLocalCountry() {
    return (
      this.data.get("localCountryId") ===
      this.storageTemp.shipping_address_country_id
    );
  }

  get localPayments() {
    if (!this._localPayments)
      this._localPayments = this.data.get("localPayments").split("|");

    return this._localPayments;
  }

  get paymentMethodByShippingRate() {
    if (!this._paymentMethodByShippingRate) {
      this._paymentMethodByShippingRate = {
        cash_only: ["Spree::PaymentMethod::Check"],
        card_only: [
          "Spree::PaymentMethod::MercadoPago",
          "Spree::PaymentMethod::Paypal",
          "Spree::PaymentMethod::WireTransfer",
        ],
      };
    }

    return this._paymentMethodByShippingRate;
  }

  async connect() {
    const orderToken = this.token;
    const response = await this.spree.checkout.paymentMethods({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const site = window.site;
    const cart = this.cart;
    const next = { url: this.data.get("nextUrl") };
    const back = { url: this.data.get("backUrl") };

    let payment_methods;

    if (!this.storageTemp.shipping_address_country_id) {
      payment_methods = response.success().data;
    } else if (this.isLocalCountry) {
      payment_methods = response
        .success()
        .data.filter((p) => this.localPayments.includes(p.attributes.type));
    } else {
      payment_methods = response
        .success()
        .data.filter((p) => !this.localPayments.includes(p.attributes.type));
    }

    const shippingRateCode = this.storageTemp.getItem("shipping_rate_code");
    const paymentMethodByShippingRate =
      this.paymentMethodByShippingRate[shippingRateCode];

    if (paymentMethodByShippingRate) {
      payment_methods = payment_methods.filter((p) =>
        paymentMethodByShippingRate.includes(p.attributes.type)
      );
    }

    this.render({ payment_methods, site, cart, next, back });
  }

  render(data = {}) {
    fetch(this.data.get("template"))
      .then((r) => r.text())
      .then((template) => {
        this.engine.parseAndRender(template, data).then((html) => {
          this.element.innerHTML = html;
          Array.from(this.formTarget.elements).forEach((p) =>
            p.addEventListener(
              "change",
              (e) => (this.submitTarget.disabled = false)
            )
          );
        });
      });
  }

  async submit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.submitTarget.disabled = true;

    // TODO: Find a more natural way to do this
    const payment_method_id =
      this.formTarget.elements[
        "order[payments_attributes][][payment_method_id]"
      ].value;
    const orderToken = this.token;

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: { payments_attributes: [{ payment_method_id }] },
        payment_source: {
          [payment_method_id]: {
            name: "Pepitx",
            month: 12,
            year: 2020,
          },
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    this.cart = response;

    response = await this.spree.checkout.complete({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    this.cart = response;

    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken });
    let redirectUrl = this.data.get("nextUrl");

    if (checkoutUrls.data.length > 0) redirectUrl = checkoutUrls.data[0];

    try {
      Turbolinks.visit(redirectUrl);
    } catch {
      window.location = redirectUrl;
    }
  }
}
