import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "toggle", "container", "placeholder", "empty"];

  unique (item, index, haystack) {
    return haystack.indexOf(item) === index;
  }

  async fetch(event) {
    this.abortController?.abort();
    this.abortController = new AbortController();

    const signal = this.abortController.signal;

    // Los items por mostrar son los que intersectan entre todas las
    // categorías
    const allItemUrls =
      this.toggleTargets
        .filter(x => x.checked)
        .map(x => JSON.parse(x.dataset.nubeDeEtiquetasUrlsParam));

    if (allItemUrls.length === 0) {
      for (const itemTarget of this.itemTargets) {
        const classes = ("nubeDeEtiquetasItemDefaultParam" in itemTarget.dataset) ? ["d-none", "d-flex"] : ["d-flex", "d-none"];

        itemTarget.classList.replace(...classes);
      }

      return;
    }

    const itemUrls = allItemUrls.reduce((itemUrls, array) => itemUrls.filter(x => array.includes(x)));

    this.emptyTarget.classList[(itemUrls.length === 0 ? "remove" : "add")]("d-none");

    // Todos los items mostrados
    const itemTargetUrls = this.itemTargets.map(x => JSON.parse(x.dataset.nubeDeEtiquetasItemUrlsParam)).flat().filter(this.unique);

    // Los items que falta descargar
    const missingItemTargetUrls = itemUrls.filter(x => !itemTargetUrls.includes(x));

    // Ocultar/mostrar los actuales
    for (const itemTarget of this.itemTargets) {
      const itemUrlsParam = JSON.parse(itemTarget.dataset.nubeDeEtiquetasItemUrlsParam);

      // Quiero saber si el item tiene alguna url contenida en itemUrls
      const classes = (itemUrls.some(x => itemUrlsParam.includes(x))) ? ["d-none", "d-flex"] : ["d-flex", "d-none"];

      itemTarget.classList.replace(...classes);
    }

    const fetches = [];

    // Cargar todos los items faltantes asincronicamente
    for (const itemTargetUrl of missingItemTargetUrls) {
      const placeholder = this.placeholderTarget.content.firstElementChild.cloneNode(true);

      this.containerTarget.appendChild(placeholder);

      fetches.push(
        fetch(itemTargetUrl, { signal }).then(response => response.text()).then(text => {
          const template = document.createElement("template");
          template.innerHTML = text;

          placeholder.replaceWith(template.content.firstElementChild);
        }).catch(e => placeholder.remove())
      );
    }

    await Promise.all(fetches);

    this.itemTargets.sort((a, b) => {
      return parseInt(a.dataset.nubeDeEtiquetasItemOrderParam) < parseInt(a.dataset.nubeDeEtiquetasItemOrderParam);
    }).forEach(x => x.parentNode.appendChild(x));
  }
}
