import { Controller } from "stimulus";

/*
 * Genera un acordeón haciendo que una tira de checkboxes se comporte
 * como un radio.  Usamos checkboxes porque los radio no facilitan la
 * navegación con teclado (especial para lectores de pantalla) cuando
 * tienen contenido y no se pueden destildar una vez seleccionados.
 */
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.anchorFound = false;

    this.inputTargets.forEach((input) => {
      input.addEventListener("change", (event) => {
        if (!event.target.checked) return;

        window.location.hash = event.target.dataset.anchor;

        this.inputTargets.forEach((other) => {
          if (input == other) return;

          other.checked = false;
        });
      });

      if (this.anchorFound) return;

      if (input.dataset.anchor === window.location.hash) {
        input.checked = true;
        this.anchorFound = true;
      }
    });
  }
}
