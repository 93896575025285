import { CartBaseController } from "./cart_base_controller";

/*
 * Register or login users.
 *
 * It brings shipping and billing addresses from the account.
 */
export default class extends CartBaseController {
  static targets = ["form", "password", "username"];

  connect() {
    if (!this.hasFormTarget) return;

    this.formTarget.addEventListener("focusout", (event) =>
      this.formTarget.classList.add("was-validated")
    );

    // Try to login first, if login fails proceed with registration
    this.passwordTarget.addEventListener("focusout", async (event) => {
      if (this.passwordTarget.value.length === 0) return;
      if (!this.formTarget.checkValidity()) return;

      const username = this.usernameTarget.value;
      const password = this.passwordTarget.value;
      // We can login after registering too
      let response = await this.spree.authentication.getToken({
        username,
        password,
      });
      let retry = false;

      this.disable();

      const site = window.site;

      // Login failed, try to register. We don't know if the account is
      // registered but the password is incorrect because the fail
      // message is the same altogether, so we have to try to register
      // anyway.
      if (response.isFail()) {
        const register = await this.spree.account.create({
          user: {
            email: username,
            password,
            password_confirmation: password,
          },
        });

        // Account exists, but password is wrong, show a message
        if (register.isFail()) {
          this.notify({
            type: "primary",
            content: site.i18n.alerts.incorrect_password,
          });
          this.enable();
          return;
        }

        this.notify({
          type: "success",
          content: site.i18n.alerts.successful_signup,
        });
        retry = true;
      } else {
        this.notify({
          type: "success",
          content: site.i18n.alerts.successful_login,
        });

        // Login again and get a token

        if (response.isFail()) {
          this.handleFailure(response);
          this.enable();
          return;
        }

        retry = true;
      }

      this.enable();

      if (retry)
        response = await this.spree.authentication.getToken({
          username,
          password,
        });

      if (response.isFail()) return;

      const bearerToken = response.success().access_token;
      const orderToken = this.token;

      // Store bearerToken for later use
      this.bearerToken = bearerToken;

      // Inform Spree to assign the order to this user
      this.spree.sutty.assignOrderOwnership({ bearerToken }, { orderToken });

      const address_types = ["shipping", "billing"];

      address_types.forEach(async (address_type) => {
        let address = await this.spree.account.accountInfo(
          { bearerToken },
          { include: `default_${address_type}_address` }
        );

        if (address.isFail()) return;
        if (address.success().included.length == 0) return;

        let data = address.success().included[0].attributes;

        window.dispatchEvent(
          new CustomEvent(`${address_type}_address:update`, {
            detail: { data },
          })
        );
      });
    });

    // We only update the email on the order
    // https://github.com/spree/spree-storefront-api-v2-js-sdk/issues/146
    Array.from(this.formTarget.elements).forEach((i) => {
      i.addEventListener("change", (event) =>
        this.storageTemp.setItem(this.idFromInputName(i), i.value)
      );
      i.value = this.storageTemp.getItem(this.idFromInputName(i));
    });
  }

  notify(data = {}) {
    window.dispatchEvent(
      new CustomEvent("notification", { detail: { template: "alert", data } })
    );
  }

  toggle() {
    this.usernameTarget.disabled = !this.usernameTarget.disabled;
    this.passwordTarget.disabled = !this.passwordTarget.disabled;
  }

  enable() {
    this.usernameTarget.disabled = false;
    this.passwordTarget.disabled = false;
  }

  disable() {
    this.usernameTarget.disabled = true;
    this.passwordTarget.disabled = true;
  }
}
