import { CartBaseController } from "./cart_base_controller";

/*
 * Updates the shipping and billing addresses
 */
export default class extends CartBaseController {
  static targets = ["form"];

  connect() {
    this.formTarget.addEventListener("focusout", async (event) => {
      if (!this.formTarget.checkValidity()) {
        this.formTarget.classList.add("was-validated");
        return;
      }

      const orderToken = this.token;
      const ship_address_attributes = {};
      const email = window.sessionStorage.getItem("email");
      const inputs = Array.from(this.formTarget.elements);

      // Collect values from inputs
      inputs.forEach(
        (input) =>
          (ship_address_attributes[this.idFromInputName(input)] = input.value)
      );

      const bill_address_attributes = ship_address_attributes;
      const response = await this.spree.checkout.orderUpdate(
        { orderToken },
        { order: { email, ship_address_attributes, bill_address_attributes } }
      );

      if (response.isFail()) {
        this.handleFailure(response);
        return;
      }

      this.assignShippingAddress();

      this.cart = response;
      window.dispatchEvent(new Event("cart:shipping:methods"));
    });

    // Fill form from event
    window.addEventListener("shipping_address:update", (event) => {
      const inputs = Array.from(this.formTarget.elements);

      for (const attribute in event.detail.data) {
        let id = `shipping_address_${attribute}`;

        switch (attribute) {
          case "country_name":
            id = "shipping_address_ignore_country_id";
            break;
          case "state_name":
            id = "nonexistent";
            break;
        }

        const input = inputs.find((x) => x.id === id);

        if (!input) continue;

        // XXX: Instruct the form to pass the next state name along
        // TODO: This controller shouldn't have to do this
        if (id === "shipping_address_ignore_country_id") {
          input.dataset.selectedState = event.detail.data.state_name;
          input.dataset.selectedZipcode = event.detail.data.zipcode;
        }

        input.value = event.detail.data[attribute];
        input.dispatchEvent(new Event("change"));
      }
    });

    this.shouldGetShippingRates = false;

    // XXX: Recover form state, values are stored when changed, remember
    // to launch ChangeEvent's if you're setting values programatically.
    Array.from(this.formTarget.elements).forEach((input) => {
      const id = `shipping_address_${this.idFromInputName(input)}`;
      const value = this.storageTemp.getItem(id);

      input.addEventListener("change", (event) =>
        this.storageTemp.setItem(id, input.value)
      );

      if (!value) return;

      input.disabled = false;
      input.value = value;

      // TODO: This controller shouldn't have to do this
      if (input.id === "shipping_address_ignore_country_id") {
        input.dataset.selectedState = this.storageTemp.getItem(
          "shipping_address_shipping_address_ignore_state_id"
        );
        input.dataset.selectedZipcode = this.storageTemp.getItem(
          "shipping_address_zipcode"
        );
      }

      this.shouldGetShippingRates = true;
    });

    // Recalculate shipping rates after the form state has been
    // recovered
    if (!this.shouldGetShippingRates) return;

    this.formTarget.dispatchEvent(new Event("focusout"));
  }

  assignShippingAddress() {
    if (!this.bearerToken) return;

    const bearerToken = this.bearerToken;
    const orderToken = this.token;

    this.spree.sutty.assignOrderShippingAddress(
      { bearerToken },
      { orderToken }
    );
  }
}
