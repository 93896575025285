import { CartBaseController } from "./cart_base_controller";

/*
 * Updates the shipping and billing addresses
 */
export default class extends CartBaseController {
  static targets = ["form", "username"];
  static values = {
    next: String,
  };

  async submit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.formTarget.checkValidity()) {
      this.formTarget.classList.add("was-validated");
      return;
    }

    const orderToken = this.token;
    const ship_address_attributes = {};
    const email = this.usernameTarget.value;

    const formData = new FormData(this.formTarget);

    this.toggleForm(true);

    for (const [field, value] of formData) {
      ship_address_attributes[field] = value;
    }

    ship_address_attributes.address1 = "-";
    ship_address_attributes.city = "-";
    ship_address_attributes.zipcode = "1111";
    ship_address_attributes.country_id = "10";
    ship_address_attributes.state_id = "66";

    const bill_address_attributes = ship_address_attributes;

    const order = { email, ship_address_attributes, bill_address_attributes };
    let response = await this.spree.checkout.orderUpdate(
      { orderToken },
      { order }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.toggleForm(false);
      return;
    }

    this.assignShippingAddress();

    this.cart = response;

    response = await this.spree.checkout.shippingMethods(
      { orderToken },
      { include: "shipping_rates" }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.toggleForm(false);
      return;
    }

    this.storageTemp.removeItem("shipping_address_country_id");

    // XXX: Lo más probable es que la tienda ya elija el envío de una,
    // pero nos aseguramos.
    const shippingRate = response
      .success()
      .included.find((x) => x.attributes.code == "retiro");

    if (response.isFail()) {
      this.handleFailure(response);
      this.toggleForm(false);
      return;
    }

    response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: {
          shipments_attributes: [
            {
              id: response.success().data[0].id,
              selected_shipping_rate_id: shippingRate.id,
            },
          ],
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.toggleForm(false);
      return;
    }

    this.cart = response;

    try {
      Turbolinks.visit(this.nextValue);
    } catch {
      window.location = this.nextValue;
    }
  }

  assignShippingAddress() {
    if (!this.bearerToken) return;

    const bearerToken = this.bearerToken;
    const orderToken = this.token;

    this.spree.sutty.assignOrderShippingAddress(
      { bearerToken },
      { orderToken }
    );
  }

  toggleForm(disabled) {
    for (const input of Array.from(this.formTarget.elements)) {
      input.disabled = disabled;
    }
  }
}
