import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const params = new URLSearchParams(window.location.search);

    console.log(params.get("open"), this.element);

    if (params.getAll("open").length === 0) return;

    this.element.checked = true;
  }
}
